/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/
import {
  Properties,
  type PromptQuestion,
} from '@agent-assist/api-typescript-react-query-hooks';
import {PromptType} from '@agent-assist/api-typescript-react-query-hooks';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {Trash2, CornerDownRight} from 'lucide-react';

import {type PromptQuestionUnique} from '../../providers/PromptProvider';
import {PromptVisualisationConfig} from '../Analytics/PromptVisualisationConfig';
import {acceptedPromptTypes} from '../Analytics/types';
import {Input} from '../ui/input';
import {Label} from '../ui/label';
import {LegacyButton} from '../ui/legacy-button';
import {Switch} from '../ui/switch';
import {Textarea} from '../ui/textarea';

import './QuestionListGridContainer.css';

type PromptQuestionWithPartialProperties = Omit<
  PromptQuestion,
  'properties'
> & {
  properties: Partial<Properties>;
};

const promptTypeOptionLabels: {value: PromptType; label: string}[] = [
  {value: 'chat', label: 'Chat'},
  {value: 'extraction_binary', label: 'Yes/No'},
  // {value: 'extraction_hierarchy', label: 'Hierarchical'}, // TODO: Review via PRODUCT-267
  {value: 'extraction_rating', label: 'Rating'},
  {value: 'extraction_single_label', label: 'One Word'},
  {value: 'synthesis', label: 'Summary'},
];

export const promptTypeOptions: {
  value: PromptType;
  label: string;
  canVisualize: boolean;
}[] = promptTypeOptionLabels.map(({value, label}) => {
  // note the reference to `acceptedPromptTypes`, which is an array of `AcceptedPromptTypes` that come from the model.
  // not a clever map trick, this is just so future devs know `canVisualize` is bound to a model.
  return {value, label, canVisualize: acceptedPromptTypes.includes(value)};
});

export type PromptQuestionItem = Partial<PromptQuestionWithPartialProperties>;

const PromptTypeSelect = ({
  question,
  index,
  updatePrompt,
}: {
  question: PromptQuestionUnique;
  index: number;
  updatePrompt: (index: number, updatedPromptItem: PromptQuestionItem) => void;
}) => {
  // const theme = useTheme();
  return (
    <div className="grid gap-2">
      <FormControl fullWidth>
        <InputLabel id="prompt-type-select-label">Prompt Type</InputLabel>
        <Select
          labelId="prompt-type-select-label"
          value={question.promptType}
          label="Prompt Type"
          onChange={(event) => {
            updatePrompt(index, {
              promptType: event.target.value as PromptType,
            });
          }}
          renderValue={(selected) => {
            const selectedOption = promptTypeOptions.find(
              (option) => option.value === selected,
            );
            return selectedOption ? selectedOption.label : '';
          }}
          className="flex h-10 w-full items-center justify-between rounded-md border border-input px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {promptTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
              {option.canVisualize && (
                <Tooltip title="Prompt Type can have Visualisation" arrow>
                  <ListItemIcon>
                    <AutoGraphIcon fontSize="small" />
                  </ListItemIcon>
                </Tooltip>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const QuestionListItem = ({
  question,
  index,
  methods,
  promptGroupName,
  isSynopsisSection,
}: {
  question: PromptQuestionUnique;
  index: number;
  methods: {
    addChildQuestion: (index: number, questionKey: string) => void;
    deleteQuestion: (index: number) => void;
    updatePrompt: (
      index: number,
      updatedPromptItem: PromptQuestionItem,
    ) => void;
  };
  promptGroupName: string;
  isSynopsisSection: boolean;
}) => {
  const {addChildQuestion, deleteQuestion, updatePrompt} = methods;
  const isQa = true; // (q.promptType as PromptType) === "qa";

  return (
    <div className="question-list-grid-container">
      <div className={'grid gap-3 mr-1 items-start p-2'} key={question.id}>
        <div className={'flex flex-row items-center'}>
          {question.properties.isChild && <CornerDownRight className="mr-2" />}
          <Input
            value={question.key}
            disabled={question.properties.isChild === true}
            onChange={(e) => updatePrompt(index, {key: e.target.value})}
            aria-label={`key ${promptGroupName} ${index + 1}`}
          />
        </div>
        <div className={'flex flex-col column-prompt-insights'}>
          <Textarea
            onChange={(e) => updatePrompt(index, {question: e.target.value})}
            defaultValue={question.question}
            aria-label={`question ${promptGroupName} ${index + 1}`}
          />
        </div>
        {isSynopsisSection ? (
          <div className={'flex flex-col column-prompt-options'}>
            <PromptTypeSelect
              question={question}
              index={index}
              updatePrompt={updatePrompt}
            />
            <PromptVisualisationConfig
              question={question}
              index={index}
              updatePrompt={updatePrompt}
            />
          </div>
        ) : (
          <div className={'flex flex-col column-prompt-options'}>
            <div
              className="grid gap-2"
              style={{
                gridTemplateColumns: '1fr 64px',
                ...(isQa ? {} : {opacity: 0.2, pointerEvents: 'none'}),
              }}
            >
              <PromptVisualisationConfig
                question={question}
                index={index}
                updatePrompt={updatePrompt}
              />
              <Label className="flex items-center">Positive</Label>
              <div>
                <Switch
                  ariaLabel="Toggle positive"
                  defaultChecked={
                    question.properties.positiveResult === undefined ||
                    question.properties.positiveResult
                  }
                  onCheckedChange={(positiveResult: boolean) =>
                    updatePrompt(index, {properties: {positiveResult}})
                  }
                  aria-label={`positive ${promptGroupName} ${index + 1}`}
                />
              </div>
              <div>
                <Label>Weight</Label>
              </div>
              <div>
                <Input
                  className="w-12 py-0 px-1 h-7"
                  type={'number'}
                  value={
                    isNaN(question.properties.weight)
                      ? ''
                      : question.properties.weight
                  }
                  onWheel={(e) =>
                    // prevent scroll events from modifying the input's value:
                    (e.target as HTMLElement)?.blur()
                  }
                  onChange={(e) => {
                    const weight = Math.max(
                      0,
                      Math.min(100, Number(e.target.value)),
                    );
                    updatePrompt(index, {properties: {weight}});
                  }}
                  aria-label={`weight ${promptGroupName} ${index + 1}`}
                />
              </div>

              {question.properties.isChild ? null : (
                <>
                  <Label className="flex items-center">CQA</Label>
                  <div>
                    <Switch
                      ariaLabel="Toggle CQA"
                      checked={question.properties.conditionalQA || false}
                      onCheckedChange={(conditionalQA: boolean) => {
                        updatePrompt(index, {
                          properties: {
                            conditionalQA,
                            positiveResult: true,
                            isParent: true,
                            isChild: false,
                          },
                        });
                        conditionalQA
                          ? addChildQuestion(index + 1, question.key)
                          : deleteQuestion(index + 1);
                      }}
                      aria-label={`conditional qa ${promptGroupName} ${index + 1}`}
                    />
                  </div>
                </>
              )}

              <Label className="flex items-center">Auto Fail</Label>
              <div>
                <Switch
                  ariaLabel="Toggle auto fail"
                  defaultChecked={question.properties.autoFail}
                  onCheckedChange={(autoFail: boolean) =>
                    updatePrompt(index, {properties: {autoFail}})
                  }
                  aria-label={`auto fail ${promptGroupName} ${index + 1}`}
                />
              </div>
            </div>
          </div>
        )}

        <div
          className={
            'flex flex-row h-full justify-center items-center column-prompt-delete'
          }
        >
          <LegacyButton
            variant={'ghost'}
            size={'sm'}
            onClick={() => deleteQuestion(index)}
            aria-label={`delete ${promptGroupName} ${index + 1}`}
          >
            <Trash2 />
          </LegacyButton>
        </div>
      </div>
    </div>
  );
};
