/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  CreatePromptRevisionRequest,
  CreatePromptRevisionResponseContent,
  PromptGroup,
  PublishPromptRevisionRequest,
  PublishPromptRevisionResponseContent,
  ResponseError,
  TestPromptForContactRequest,
  TestPromptForContactResponseContent,
} from '@agent-assist/api-typescript-react-query-hooks';
import {Box} from '@mui/material';
import {UseMutationResult} from '@tanstack/react-query';
import {AlertCircle, Copy} from 'lucide-react';
import React, {FC, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  PromptPlaygroundForm,
  type PromptFormData,
} from './PromptPlaygroundForm';
import {usePromptCreateModal} from '../../hooks/modal/prompt-create-modal';
import {ErrorPage} from '../../pages/error/error-page';
import {ContactSummary} from '../contact/contact-summary';
import CopyButton from '../CopyButton';
import LegacyHeading from '../Heading';
import {LoadingSpinner} from '../LoadingSpinner';
import {Alert, AlertDescription} from '../ui/alert';
import {LegacyButton} from '../ui/legacy-button';

export interface PromptPlaygroundProps {
  readonly contactId: string;
  readonly contactImportId?: string;
  readonly testPrompt: UseMutationResult<
    TestPromptForContactResponseContent,
    ResponseError,
    TestPromptForContactRequest
  >;
  readonly publishPrompt: UseMutationResult<
    PublishPromptRevisionResponseContent,
    ResponseError,
    PublishPromptRevisionRequest
  >;
  readonly createPrompt: UseMutationResult<
    CreatePromptRevisionResponseContent,
    ResponseError,
    CreatePromptRevisionRequest
  >;
  // prompt playground being depreciated, this is a hack
  readonly promptRevision: PromptGroup & {name: string; overallScore?: number};
  readonly hideDrawer: () => void;
}

export const PromptPlayground: FC<PromptPlaygroundProps> = ({
  contactId,
  contactImportId,
  testPrompt,
  publishPrompt,
  createPrompt,
  promptRevision,
  hideDrawer,
}) => {
  const navigate = useNavigate();
  const [promptName, setPromptName] = useState<string>(promptRevision.name);
  const [promptFormData, setPromptFormData] =
    useState<PromptFormData>(promptRevision);

  const onTestPrompt = useCallback(async () => {
    const questionsWithId = promptFormData.questions.map((question) => {
      return {...question, id: Math.random().toString(36).substring(2, 12)};
    });
    testPrompt.mutate({
      testPromptForContactRequestContent: {
        contactImportId,
        contactId,
        promptQuestions: questionsWithId,
      },
    });
  }, [testPrompt, contactId, promptFormData, contactImportId]);

  const onCreate = useCallback(async () => {
    // Create and publish the prompt
    const newPrompt = await createPrompt.mutateAsync({
      createPromptRevisionRequestContent: {
        promptGroups: {
          synopsis: {
            promptGroupType: 'synopsis',
            totalWeight: 100,
            questions: promptFormData.questions,
          },
        },
        name: promptName,
      },
    });
    navigate(`/prompts/${newPrompt.promptRevisionId}`);
  }, [promptFormData.questions, navigate, promptName]);

  const synopsisCopyValue = React.useMemo(
    () =>
      testPrompt.data?.synopsis
        ?.map((s) => `${s.key}\n${s.response}`)
        .join('\n\n') || '',
    [testPrompt.data],
  );

  const publishConfirmation = usePromptCreateModal({
    onConfirm: onCreate,
    onChangeName: setPromptName,
    name: promptName,
  });

  const disableButtons =
    promptFormData.questions.length === 0 ||
    testPrompt.isLoading ||
    createPrompt.isLoading ||
    publishPrompt.isLoading;

  return (
    <>
      {publishConfirmation.modal}
      <Box className={'flex w-full'}>
        <Box className={'flex flex-col w-full gap-y-4 px-5'}>
          <div className={'flex justify-end gap-x-2 items-center'}>
            <LegacyButton
              disabled={disableButtons}
              onClick={() => {
                hideDrawer();
                publishConfirmation.show();
              }}
              variant={'secondary'}
              isLoading={createPrompt.isLoading || publishPrompt.isLoading}
            >
              Save
            </LegacyButton>
            <LegacyButton
              isLoading={testPrompt.isLoading}
              disabled={disableButtons}
              onClick={onTestPrompt}
            >
              Test
            </LegacyButton>
          </div>
          <PromptPlaygroundForm
            disabled={testPrompt.isLoading}
            data={promptFormData}
            onChange={setPromptFormData}
          />
        </Box>
        <Box className={'flex flex-col w-full gap-y-4 px-5 border-l'}>
          <div className={'flex justify-between items-center'}>
            <LegacyHeading>Insights</LegacyHeading>
            {testPrompt.data?.synopsis ? (
              <CopyButton
                variant="ghost"
                size={'icon'}
                value={synopsisCopyValue}
              >
                <Copy size={20} />
              </CopyButton>
            ) : null}
          </div>
          {testPrompt.isLoading ? (
            <div className={'flex w-full items-center justify-center h-24'}>
              <LoadingSpinner />
            </div>
          ) : testPrompt.isError ? (
            <ErrorPage errors={[testPrompt.error]} />
          ) : testPrompt.data ? (
            <div className={'py-4'}>
              <ContactSummary summary={testPrompt.data} />
            </div>
          ) : (
            <Alert>
              <AlertCircle className={'h-4 w-4'} />
              <AlertDescription>
                Enter your prompt questions on the left and click "Test" to try
                out a new summary.
              </AlertDescription>
            </Alert>
          )}
        </Box>
      </Box>
    </>
  );
};
