/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  ActionType,
  useCreatePromptRevision,
  useGetAudioPresignedURL,
  useGetBatchContactImport,
  useGetContact,
  useGetContactMetadata,
  useGetPromptRevision,
  useListTranscripts,
  usePublishPromptRevision,
  useReTryContactAction,
  useTestPromptForContact,
} from '@agent-assist/api-typescript-react-query-hooks';
import {
  PartialTranscript,
  TranscriptUpdateNotificationPayload,
} from '@agent-assist/common';
import {Box, Card, CardContent, Link, Stack, Tooltip} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {format} from 'date-fns';
import humanizeDuration from 'humanize-duration';
import orderBy from 'lodash/orderBy';
import {Clock, Copy, Info} from 'lucide-react';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {Link as RouterLink, useParams} from 'react-router-dom';

import {TranscriptBubble} from './transcript/transcript-bubble';
import {GetBatchContactImportResponseContent} from '../../../../api/generated/runtime/typescript/lib/models/GetBatchContactImportResponseContent';
import AudioPlayerAccordion from '../../components/AudioPlayer';
import {ContactConnectButton} from '../../components/contact/contact-connect-button';
import {ContactSourceIndicator} from '../../components/contact/contact-source-indicator';
import CopyButton from '../../components/CopyButton';
import LegacyHeading from '../../components/Heading';
import PromptPlaygroundDrawer from '../../components/PromptPlayground/PromptPlaygroundDrawer';

import {
  contactFailedActions,
  contactSuccessActions,
  RetryImportAction,
} from '../../components/RetryImportAction/RetryImportAction';
import StatusIndicator, {
  StatusTypeEnum,
} from '../../components/StatusIndicator';
import {PageContainer} from '../../components/ui/page';
import {Switch} from '../../components/ui/switch';
import {useNotificationSubscription} from '../../hooks/notifications/subscription';
import {setAudioUrl, usePromptDispatch} from '../../providers/PromptProvider';
import {mapResults} from '../contact-import/ViewListComplete';
import {ErrorPage} from '../error/error-page';

import './view-contact.css';

import {LoadingSpinner} from '../../components/LoadingSpinner';
import {Alert} from '@mui/lab';
import {ScreenLoader} from '../../components/ScreenLoader';
import {ContactSummaryContainer} from '../../components/contact/contact-summary-container';

interface ViewContactProps {}
type ContactTimes = {startTime: string; endTime: string};

type Params = {
  contactId: string;
  contactImportId: string;
};

export const ContactViewPage: FC<ViewContactProps> = () => {
  const [localAudioUrl, setLocalAudioUrl] = useState<string | undefined>(
    undefined,
  );
  const dispatch = usePromptDispatch();
  const {contactId, contactImportId} = useParams<Params>();

  if (!contactId || !contactImportId)
    throw new Error('contactId & contactImportId is required');

  const [isTailTranscript, setIsTailTranscript] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<'endOffset' | 'startOffset'>(
    'endOffset',
  );
  const contact = useGetContact({contactId, contactImportId});
  const contactMetaApi = useGetContactMetadata({contactId, contactImportId});
  const transcripts = useListTranscripts({
    contactId,
    contactImportId,
    pageSize: 10,
  });
  const transcriptScrollContainer = useRef<HTMLDivElement>(null);
  const testPrompt = useTestPromptForContact();
  const createPrompt = useCreatePromptRevision();
  const publishPrompt = usePublishPromptRevision();
  const retryAction = useReTryContactAction();
  /*  TODO: We should control api call, only call if there is an audioS3Key
   For now will leave it as it is, because existing imports does not have that key
  */
  const parentImport = contactImportId
    ? useGetBatchContactImport({contactImportId})
    : useGetContact({contactId});

  const getPresignedUrl = contactImportId
    ? useGetAudioPresignedURL({
        contactId,
        contactImportId,
      })
    : null;

  const hasAudio = contact.data?.audioS3Key || localAudioUrl;
  useEffect(() => {
    if (getPresignedUrl) {
      setAudioUrl(dispatch, getPresignedUrl.data?.url);
      setLocalAudioUrl(getPresignedUrl.data?.url);
    }
  }, [getPresignedUrl?.data?.url]);

  const productionSummary = contact.data?.summary?.production;
  const inferredPromptRevision = useGetPromptRevision(
    {
      promptRevisionId: parentImport.data?.promptRevisionId ?? 'n/a',
    },
    {
      enabled:
        productionSummary?.status === 'SUCCESS' &&
        !!productionSummary?.promptRevisionId,
    },
  );

  const [updatedContact, setUpdatedContact] = useState<string>();
  const onContactStatusUpdate = useCallback(() => {
    void contact.refetch();
  }, [contact]);

  const [liveUtterances, setLiveUtterances] = useState<PartialTranscript[]>([]);
  const [liveTranscripts, setLiveTranscripts] = useState<PartialTranscript[]>(
    [],
  );

  useEffect(() => {
    if (
      (liveUtterances.length > 0 ||
        liveTranscripts.length > 0 ||
        isTailTranscript) &&
      transcripts.hasNextPage &&
      !transcripts.isFetchingNextPage
    ) {
      void transcripts.fetchNextPage();
    }
    // Scroll to bottom when tail is true
    if (isTailTranscript && transcriptScrollContainer.current) {
      transcriptScrollContainer.current.scrollTop =
        transcriptScrollContainer.current.scrollHeight;
    }
  }, [
    isTailTranscript,
    liveTranscripts.length,
    liveUtterances.length,
    transcripts,
    transcripts.data?.pages?.length,
    transcriptScrollContainer.current,
  ]);

  /*
  const [fetchAllPages, setFetchAllPages] = useState(false);
  useEffect(() => {
    if (fetchAllPages && transcripts.hasNextPage && !transcripts.isFetchingNextPage) {
      console.log("fetching", (transcripts.data?.pages?.length ?? 0) + 1);
      void transcripts.fetchNextPage();
    }
  }, [transcripts, fetchAllPages, transcripts.isFetchingNextPage]);
  // to fetch all pages, add this button:
  <button onClick={() => setFetchAllPages(true)}>Fetch all</button>
  */

  useEffect(() => {
    if (updatedContact) {
      void contact.refetch();
    }
  }, [updatedContact]);
  const onTranscriptUpdate = useCallback(
    (notification: TranscriptUpdateNotificationPayload) => {
      setLiveTranscripts((prev) => [...prev, ...notification.transcripts]);
      setLiveUtterances((prev) => [...prev, ...notification.utterances]);
    },
    [transcripts],
  );

  useEffect(() => {
    if (contact.data?.status === 'COMPLETED') {
      setIsTailTranscript(false);
    }
  }, [contact.data]);
  useNotificationSubscription(contactId, {
    onContactStatusUpdate,
    onTranscriptUpdate,
  });

  const applyActionType = useCallback(
    async (actionType: ActionType) => {
      await retryAction.mutateAsync({
        contactIds: [contactId],
        contactImportId,
        actionType,
      });
      setUpdatedContact(contactId);
    },
    [retryAction, setUpdatedContact, contact],
  );

  const allTranscripts: PartialTranscript[] = useMemo(() => {
    // Existing transcripts we loaded from the api
    const existingTranscripts =
      transcripts.data?.pages?.flatMap?.((p) => p.transcripts) ?? [];
    const existingTranscriptsById = Object.fromEntries(
      existingTranscripts.map((t) => [t.transcriptId, t]),
    );
    // New live transcripts are the transcripts we received via websocket
    const newLiveTranscripts = liveTranscripts.filter(
      (t) => !existingTranscriptsById[t.transcriptId],
    );
    const newLiveTranscriptsById = Object.fromEntries(
      newLiveTranscripts.map((t) => [t.transcriptId, t]),
    );
    // New utterances are the utterances received via websocket which have not been superseded by a transcript.
    const newUtterances = liveUtterances
      .filter(
        // Prefer transcripts to utterances since they are considered "complete"
        (u) =>
          !newLiveTranscriptsById[u.transcriptId] &&
          !existingTranscriptsById[u.transcriptId],
      )
      .map((u, i) => ({
        ...u,
        // There may be multiple utterances for the same transcript, so we make the transcriptId unique for now.
        transcriptId: u.transcriptId + i,
      }));
    return orderBy(
      [...existingTranscripts, ...newLiveTranscripts, ...newUtterances],
      sortBy,
    );
  }, [transcripts.data, liveTranscripts, liveUtterances, sortBy]);

  const transcriptCopyValue = useMemo(() => {
    const loadedTranscripts = allTranscripts.map(
      (t) => `${t.participant[0]}: ${t.content}`,
    );
    if (transcripts.hasNextPage) {
      loadedTranscripts.push('...', '<transcript is incomplete>');
    }
    return loadedTranscripts.join('\n');
  }, [allTranscripts, transcripts.hasNextPage]);

  const [contactMeta, setContactMeta] = useState<ContactTimes | undefined>(
    undefined,
  );

  useEffect(() => {
    if (contactMetaApi.data?.result) {
      setContactMeta(
        contactMetaApi.data.result
          .map(mapResults)
          .at(0) as unknown as ContactTimes, // meta data has all sorts of unknown keys
      );
    }
  }, [contactImportId, contactId, contactMetaApi.data]);

  if (contact.isError || transcripts.isError) {
    return <ErrorPage errors={[contact.error, transcripts.error]} />;
  }

  if (contact.isLoading || !contact.data) {
    return <ScreenLoader />;
  }
  const disabled =
    contact.data?.status === 'IN_PROGRESS' || retryAction.isLoading;
  const options =
    contact.data?.status === 'COMPLETED'
      ? contactSuccessActions
      : contactFailedActions;
  const disabledIndexes = localAudioUrl ? [] : [2]; // disable the last option for Transcription when does not have audio
  const hideAudioPlayback = contact.data?.status === 'IN_PROGRESS';

  const actions = (
    <>
      <RetryImportAction
        disabled={disabled}
        applyActionType={applyActionType}
        options={options}
        disabledIndex={disabledIndexes}
      />
      <ContactConnectButton
        contactId={contactId}
        instanceId={contact.data.connectInstanceId}
      />
    </>
  );

  return (
    <PageContainer title={`Contact`} subtitle={contactId} actions={actions}>
      <Grid
        container
        spacing={4}
        columns={3}
        sx={{
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        <Grid size={1} sx={{display: 'flex'}}>
          <Card sx={{flex: 1}}>
            <CardContent>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <LegacyHeading h3>Status:</LegacyHeading>
                  <StatusIndicator
                    status={
                      retryAction.isLoading
                        ? ('IN_PROGRESS' as StatusTypeEnum)
                        : (contact.data.status as StatusTypeEnum)
                    }
                  />
                </Stack>
                <Stack spacing={1}>
                  <LegacyHeading h3>Duration:</LegacyHeading>
                  <p>
                    {contact.data.completedAt
                      ? humanizeDuration(
                          contact.data.completedAt - contact.data.startedAt,
                          {largest: 2, round: true},
                        )
                      : undefined}
                  </p>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={1} sx={{display: 'flex'}}>
          <Card sx={{flex: 1}}>
            <CardContent>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <DisplayTime
                    isLoading={contactMetaApi.isLoading}
                    time={contactMeta?.startTime}
                    title="Started at"
                  />
                </Stack>
                <Stack spacing={1}>
                  <LegacyHeading h3>Insights Prompt:</LegacyHeading>
                  <Link
                    to={`/prompts/${productionSummary?.promptRevisionId}`}
                    component={RouterLink}
                  >
                    {inferredPromptRevision.data?.name ??
                      productionSummary?.promptRevisionId}
                  </Link>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={1} sx={{display: 'flex'}}>
          <Card sx={{flex: 1}}>
            <CardContent>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <DisplayTime
                    isLoading={contactMetaApi.isLoading}
                    time={contactMeta?.endTime}
                    title="Finished at"
                  />
                </Stack>
                <Stack spacing={1}>
                  <LegacyHeading h3>Source:</LegacyHeading>
                  <ContactSourceIndicator
                    source={contact.data.source}
                    contactImport={
                      contactImportId
                        ? (parentImport.data as GetBatchContactImportResponseContent)
                        : undefined
                    }
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {!hideAudioPlayback && contact.data.s3Location && hasAudio && (
        <Card className="view-contact-header">
          <CardContent>
            <AudioPlayerAccordion
              contactId={contact.data.contactId}
              contactImportId={contactImportId}
            />
          </CardContent>
        </Card>
      )}
      <Grid container spacing={4}>
        <Grid size={8}>
          <Card>
            <CardContent
              className={'overflow-y-scroll w-full min-h-[400px] max-h-[70vh]'}
              ref={transcriptScrollContainer}
            >
              <div
                className={
                  'view-contact-transcript flex justify-between w-full'
                }
              >
                <LegacyHeading h2>Transcript</LegacyHeading>
                <div className={'flex justify-end items-center gap-x-2'}>
                  <Switch
                    ariaLabel="Sort transcript by"
                    labelOn="Sort by end"
                    onCheckedChange={(checked) =>
                      setSortBy(checked ? 'endOffset' : 'startOffset')
                    }
                    checked={sortBy === 'endOffset'}
                  />
                  <Switch
                    ariaLabel="Tail transcript"
                    labelOn="Tail transcript"
                    onCheckedChange={(checked) => setIsTailTranscript(checked)}
                    checked={isTailTranscript}
                  />
                  <CopyButton
                    variant="ghost"
                    size={'icon'}
                    value={transcriptCopyValue}
                  >
                    <Copy size={20} />
                  </CopyButton>
                </div>
              </div>
              <InfiniteScroll
                pageStart={0}
                useWindow={false}
                initialLoad={true}
                threshold={100}
                loadMore={() => {
                  if (!transcripts.isFetchingNextPage) {
                    void transcripts.fetchNextPage();
                  }
                }}
                hasMore={transcripts.hasNextPage}
                loader={
                  <div
                    key="view-contact-infinite-scroll-loader"
                    className={'flex w-full items-center justify-center my-2'}
                  >
                    <LoadingSpinner size={'sm'} />
                  </div>
                }
              >
                <div className={'flex flex-1 flex-col px-4 py-2 gap-y-'}>
                  {allTranscripts.map((t, i) => (
                    <TranscriptBubble
                      key={t.transcriptId}
                      startedAt={contact.data.startedAt}
                      transcript={t}
                      prev={allTranscripts[i - 1]}
                      next={allTranscripts[i + 1]}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={4}>
          <ContactSummaryContainer
            contact={contact}
            regenerateIsLoading={false}
          />
        </Grid>
      </Grid>
      {contact.data && inferredPromptRevision.data ? (
        <>
          <PromptPlaygroundDrawer
            contactData={contact.data}
            inferredPromptRevision={inferredPromptRevision}
            contactImportId={contactImportId}
            contactId={contactId}
            testPrompt={testPrompt}
            publishPrompt={publishPrompt}
            createPrompt={createPrompt}
          />
          <Box height={1} />
        </>
      ) : (
        <Alert severity="warning">No contact data</Alert>
      )}
    </PageContainer>
  );
};

interface DisplayTimeProps {
  title: string;
  time?: string;
  isLoading: boolean;
}

const DisplayTime = ({title, time, isLoading}: DisplayTimeProps) => {
  return (
    <>
      <LegacyHeading h3>{title}:</LegacyHeading>
      <p className="flex items-center">
        {isLoading ? (
          <LoadingSpinner size={'xs'} />
        ) : time ? (
          format(new Date(time), 'd MMM yy, h:mm aaa')
        ) : (
          <Tooltip title={`No data was found for ${title}`}>
            <div className="relative w-7">
              <Clock className="text-yellow-500" />
              <Info
                size={12}
                className="bg-accent absolute top-0 right-0 rounded-full"
              />
            </div>
          </Tooltip>
        )}
        &nbsp;{/* ensure consistent height across all states */}
      </p>
    </>
  );
};

export default ContactViewPage;
