/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {GetContactResponseContent} from '@agent-assist/api-typescript-react-query-hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Button, Card, CardContent} from '@mui/material';
import {SyntheticEvent, useCallback, useEffect, useState} from 'react';

import {PromptPlayground, PromptPlaygroundProps} from './PromptPlayground';
import {ErrorPage} from '../../pages/error/error-page';
import {LoadingSpinner} from '../LoadingSpinner';
import {Alert} from '../ui/alert';

interface PromptPlaygroundDrawerProps
  extends Omit<PromptPlaygroundProps, 'promptRevision' | 'hideDrawer'> {
  contactData: GetContactResponseContent;
  inferredPromptRevision: any;
}

const MIN_DRAWER_HEIGHT = 59;
const MAX_HEIGHT = 90; // percentage of screen height
const HEIGHT_BUFFER = 5;

const PromptPlaygroundDrawer = ({
  contactData,
  inferredPromptRevision,
  ...delegated
}: PromptPlaygroundDrawerProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const [yOffsetWithinClickZone, setYOffset] = useState(0);
  const [height, setHeight] = useState(MIN_DRAWER_HEIGHT);
  const toggleDrawer = useCallback(
    (e: SyntheticEvent, windowHeight: number) => {
      e.stopPropagation();
      e.preventDefault();

      const maxHeight = Math.round(windowHeight * (MAX_HEIGHT / 100));
      setHeight((prev) =>
        prev > MIN_DRAWER_HEIGHT ? MIN_DRAWER_HEIGHT : maxHeight,
      );
    },
    [setHeight],
  );

  const enableResize = useCallback(
    (e: SyntheticEvent) => {
      const rect = (e.target as HTMLElement).getBoundingClientRect();
      const y = (e.nativeEvent as MouseEvent).clientY - rect.top; // use this offset to add to resize position!
      setYOffset(y);

      e.preventDefault();
      setIsResizing(true);
    },
    [setIsResizing],
  );

  const disableResize = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      setIsResizing(false);
    },
    [setIsResizing],
  );

  const resize = useCallback(
    (e: MouseEvent) => {
      // e.preventDefault(); // do not prevent default, this makes text selecting impossible

      if (!isResizing) {
        return;
      }

      let newHeight = window.innerHeight - e.clientY + yOffsetWithinClickZone;
      const maxHeight = Math.round(window.innerHeight * (MAX_HEIGHT / 100));

      if (
        height > MIN_DRAWER_HEIGHT &&
        newHeight <= MIN_DRAWER_HEIGHT + HEIGHT_BUFFER
      ) {
        newHeight = MIN_DRAWER_HEIGHT;
      }

      if (newHeight >= MIN_DRAWER_HEIGHT && newHeight <= maxHeight) {
        setHeight(newHeight);
      }
    },
    [disableResize, isResizing, height],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  if (!contactData.summary?.production) {
    return (
      <div className={'flex w-full py-4'}>
        <Alert>
          Prompt playground is only available for completed contacts.
        </Alert>
      </div>
    );
  }

  const {promptRevisionId, status, statusDetails} =
    contactData.summary.production;

  return (
    <Card
      sx={{
        height,
        zIndex: 2000,
        position: 'fixed',
        bottom: 0,
        left: {
          xs: 0,
          lg: 'calc(var(--SideNav-width) - 1px)',
        },
        right: 0,
        overflow: height === MIN_DRAWER_HEIGHT ? 'hidden' : 'auto',
        borderRadius: 0,
      }}
      variant="outlined"
    >
      <div
        onMouseDown={enableResize}
        className={'flex items-center justify-between cursor-row-resize px-4'}
        style={{
          height: MIN_DRAWER_HEIGHT,
          minHeight: MIN_DRAWER_HEIGHT,
        }}
      >
        <p className={'text-xl font-semibold'}>Prompt Playground</p>
        <Button onClick={(e) => toggleDrawer(e, window.innerHeight)}>
          {height > MIN_DRAWER_HEIGHT ? (
            <ExpandMoreIcon fontSize="medium" />
          ) : (
            <ExpandLessIcon fontSize="medium" />
          )}
        </Button>
      </div>
      <CardContent>
        {status === 'SUCCESS' &&
        promptRevisionId &&
        inferredPromptRevision.isLoading ? (
          <div className={'flex w-full items-center justify-center h-24'}>
            <LoadingSpinner />
          </div>
        ) : inferredPromptRevision.data && status === 'SUCCESS' ? (
          <PromptPlayground
            {...delegated}
            promptRevision={inferredPromptRevision.data.promptGroups.synopsis}
            hideDrawer={() => setHeight(MIN_DRAWER_HEIGHT)}
          />
        ) : status === 'FAILURE' ? (
          <div className={'flex w-full py-4'}>
            <Alert variant="destructive">{statusDetails}</Alert>
          </div>
        ) : inferredPromptRevision.isError ? (
          <div className={'flex w-full py-4'}>
            <ErrorPage errors={[inferredPromptRevision.error]} />
          </div>
        ) : (
          <div className={'flex w-full py-4'}>
            <Alert>
              Prompt playground is only available for completed contacts.
            </Alert>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PromptPlaygroundDrawer;
